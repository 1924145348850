
import { computed, defineComponent, ref, watch } from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import * as Yup from "yup";
import { Field } from 'vee-validate'
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import { CompanySelect, HandleState } from "@/core/composite/composite";
import CompanyService from "@/core/services/CompanyService";
import { ILocation } from "@/core/entity/ILocation";
import store from "@/store";

export default defineComponent({
  name: "CompanyForm",
  components: {BaseSelect, FormErrorMessage, BaseForm, Field},
  props: {
    companyId: {type: String},
    company: {type: Object}
  },
  emits: ['companySaved'],
  setup(props, {emit}) {
    const submitting = ref(false)
    const model = ref(props.company)
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      code: Yup.string().required().label("Code"),
      billingAddress: Yup.string().max(999),
    });

    watch(()=> props.company, (cb)=>{
      model.value = cb;
    });
    const onSubmit = () => {
      submitting.value = true;
      if (props.companyId) {
        CompanyService.update(props.companyId, model.value).then(res => {
          emit('companySaved', res)
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        CompanyService.create(model.value).then(res => {
          emit('companySaved', res)
        }).finally(() => {
          submitting.value = false;
        })
      }
    }

    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries);
    const states = computed<ILocation[]>(() => store.state.LocationModule.states);
    return {
      countries,
      states,
      validate,
      submitting,
      model,
      ...CompanySelect(),
      onSubmit,
      ...HandleState(),
    }
  },
  methods: {
    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name && this.model) this.model.address.stateName = name
    },

    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name && this.model) this.model.address.countryName = name;
    },
  }
})
